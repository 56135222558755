import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import { Link } from "react-router-dom";
import CategorySlider from "../Home/CategorySlider";

function Categorys() {
  const get_category_Filter = useSelector((state) =>
    state?.allapi?.get_category_Filter ? state?.allapi?.get_category_Filter : []
  );
  const [childcateee, setchildcateee] = useState("");
  console.log(get_category_Filter);

  const dispatch = useDispatch();
  const henldetofilterbyCate = (e) => {
    // if (search) {
    //   dispatch(
    //     allapiAction.userSearchProduct({ page: pageno, id: search, cateId: e })
    //   );
    // }
  };
  useEffect(() => {
    dispatch(allapiAction.getfiltermaincategory({}));
    dispatch(allapiAction.getsliderList({ page: "mainCategoryPage" }));
    return () => {};
  }, []);

  return (
    <div>
      {" "}
      <div class="row p-7 ps-layout--shop ">
        <div class="col-md-3 mobile_hide">
          <div class="ps-layout__left">
            <aside class="widget widget_shop">
              <h4 class="widget-title">Categories</h4>
              <ul class="ps-list--categories">
                {get_category_Filter &&
                  get_category_Filter?.map((data, i) => {
                    return (
                      <li class="menu-item-has-children" key={i}>
                        <a href="#">{data?.name}</a>
                        <span
                          class={
                            childcateee == data?._id
                              ? "sub-toggle active "
                              : "sub-toggle"
                          }
                          onClick={() => {
                            setchildcateee(data?._id);
                          }}
                        >
                          <i class="fa fa-angle-down"></i>
                        </span>
                        <ul
                          class="sub-menu"
                          style={
                            childcateee == data?._id
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {data?.subcates &&
                            data?.subcates?.map((datachild, index) => {
                              return (
                                <li key={index}>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      henldetofilterbyCate(datachild?._id);
                                    }}
                                  >
                                    {datachild?.name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                    );
                  })}
              </ul>
            </aside>
          </div>
          {/*  <div class="list-group all_cat_line">
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
            <a
              href="#category1"
              class="list-group-item list-group-item-action"
              data-toggle="collapse"
            >
              Category 1
            </a>
          </div> */}
        </div>
        <div class="col-md-9 col-12">
          <div id="other-data">
            <div class="row p-4">
              {get_category_Filter &&
                get_category_Filter?.map((data, i) => {
                  return (
                  
                      <div class="col-3 col-md-2 mb-4">
                        <Link
                          to={"/sub-category/" + data?.slug}
                          className="all_categoryimagetest"
                        >
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt="Category 1"
                            class="all_category-image"
                          />
                        </Link>
                        <h6 class="text-center mt-5">
                          <b>{data?.name}</b>
                        </h6>
                      </div>
                   
                  );
                })}

              {/* <div class="col-2 col-md-2 mb-4">
                <a href="/mySubCategory">
                  <img
                    src="https://th.bing.com/th/id/OIP.8xVk2zjZpk1Qb_lg1NmCTAHaHa?rs=1&pid=ImgDetMain"
                    alt="Category 2"
                    class="all_category-image"
                  />
                </a>
                <h6 class="text-center mt-5">
                  <b>Man Fashion</b>
                </h6>
              </div>
              <div class="col-2 col-md-2 mb-4">
                <a href="/mySubCategory">
                  <img
                    src="https://th.bing.com/th/id/OIP.8xVk2zjZpk1Qb_lg1NmCTAHaHa?rs=1&pid=ImgDetMain"
                    alt="Category 3"
                    class="all_category-image"
                  />
                </a>
                <h6 class="text-center mt-5">
                  <b>Man Fashion</b>
                </h6>
              </div>
              <div class="col-2 col-md-2 mb-4">
                <a href="/mySubCategory">
                  <img
                    src="https://th.bing.com/th/id/OIP.8xVk2zjZpk1Qb_lg1NmCTAHaHa?rs=1&pid=ImgDetMain"
                    alt="Category 4"
                    class="all_category-image"
                  />
                </a>
                <h6 class="text-center mt-5">
                  <b>Man Fashion</b>
                </h6>
              </div>
              <div class="col-2 col-md-2 mb-4">
                <a href="/mySubCategory">
                  <img
                    src="https://th.bing.com/th/id/OIP.8xVk2zjZpk1Qb_lg1NmCTAHaHa?rs=1&pid=ImgDetMain"
                    alt="Category 5"
                    class="all_category-image"
                  />
                </a>
                <h6 class="text-center mt-5">
                  <b>Man Fashion</b>
                </h6>
              </div>
              <div class="col-2 col-md-2 mb-4">
                <a href="/mySubCategory">
                  <img
                    src="https://th.bing.com/th/id/OIP.8xVk2zjZpk1Qb_lg1NmCTAHaHa?rs=1&pid=ImgDetMain"
                    alt="Category 6"
                    class="all_category-image"
                  />
                </a>
                <h6 class="text-center mt-5">
                  <b>Man Fashion</b>
                </h6>
              </div> */}
            </div>
         
            <div class="row p-10">
            

              {/* {get_category_Filter &&
                get_category_Filter?.map((data, i) => {
                  return (
                    i > 6 && (
                      <div class="col-2 col-md-2 mb-4 mt-2">
                        <Link
                          to={"/sub-category/" + data?.slug}
                          className="all_categoryimagetest"
                        >
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            alt="Category 1"
                            class="all_category-image"
                          />
                        </Link>
                        <h6 class="text-center mt-5">
                          <b>{data?.name}</b>
                        </h6>
                      </div>
                    )
                  );
                })} */}
                <div class="col-md-12 col-12 shop_by_cat">
                <h3 class="text-center text-white p-4">
                  <b>Shop by category</b>
                </h3>
              </div>
              {/* {get_category_Filter &&
                get_category_Filter?.map((data, i) => {
                  return (
                    i > 6 && (
                      <div class="col-md-2 col-4 p-5">
                        <div class="card pb-25">
                          <img
                            src={URL?.API_BASE_URL + data?.image}
                            class="my_all_cat_images"
                          />
                          <h6 class="text-center pt-10">
                            <b>{data?.name}</b>
                          </h6>
                        </div>
                      </div>
                    )
                  );
                })} */}

              {/* <div class="col-md-2 col-4 p-5">
                <div class="card pb-25">
                  <img
                    src="https://i.pinimg.com/originals/3b/07/da/3b07dae475603600fbb1a4bb0bafef6f.png"
                    class="my_all_cat_images"
                  />
                  <h6 class="text-center pt-10">
                    <b>Product</b>
                  </h6>
                </div>
              </div>
              <div class="col-md-2 col-4 p-5">
                <div class="card pb-25">
                  <img
                    src="https://th.bing.com/th/id/OIP.RFnEKabBollap8uJieMHGAHaE8?rs=1&pid=ImgDetMain"
                    class="my_all_cat_images"
                  />
                  <h6 class="text-center pt-10">
                    <b>Product</b>
                  </h6>
                </div>
              </div>
              <div class="col-md-2 col-4 p-5">
                <div class="card pb-25">
                  <img
                    src="https://th.bing.com/th/id/R.58b058ee794fa1752cbfa5982d25ce9c?rik=K2euTydp4GzKQA&riu=http%3a%2f%2fwallpapercave.com%2fwp%2fwp1879447.jpg&ehk=CpxNDGxy2ejg8F5HspnaIv8TuptO7ZjO1UtRv75ciAc%3d&risl=&pid=ImgRaw&r=0"
                    class="my_all_cat_images"
                  />
                  <h6 class="text-center pt-10">
                    <b>Product</b>
                  </h6>
                </div>
              </div>
              <div class="col-md-2 col-4 p-5">
                <div class="card pb-25">
                  <img
                    src="https://th.bing.com/th/id/OIP.RFnEKabBollap8uJieMHGAHaE8?rs=1&pid=ImgDetMain"
                    class="my_all_cat_images"
                  />
                  <h6 class="text-center pt-10">
                    <b>Product</b>
                  </h6>
                </div>
              </div>
              <div class="col-md-2 col-4 p-5">
                <div class="card pb-25">
                  <img
                    src="https://th.bing.com/th/id/R.58b058ee794fa1752cbfa5982d25ce9c?rik=K2euTydp4GzKQA&riu=http%3a%2f%2fwallpapercave.com%2fwp%2fwp1879447.jpg&ehk=CpxNDGxy2ejg8F5HspnaIv8TuptO7ZjO1UtRv75ciAc%3d&risl=&pid=ImgRaw&r=0"
                    class="my_all_cat_images"
                  />
                  <h6 class="text-center pt-10">
                    <b>Product</b>
                  </h6>
                </div>
              </div>
              <div class="col-md-2 col-4 p-5">
                <div class="card pb-25">
                  <img
                    src="https://i.pinimg.com/originals/3b/07/da/3b07dae475603600fbb1a4bb0bafef6f.png"
                    class="my_all_cat_images"
                  />
                  <h6 class="text-center pt-10">
                    <b>Product</b>
                  </h6>
                </div>
              </div>
              <div class="col-md-2 col-4 p-5">
                <div class="card pb-25">
                  <img
                    src="https://th.bing.com/th/id/OIP.RFnEKabBollap8uJieMHGAHaE8?rs=1&pid=ImgDetMain"
                    class="my_all_cat_images"
                  />
                  <h6 class="text-center pt-10">
                    <b>Product</b>
                  </h6>
                </div>
              </div> */}
            </div>
            <div class="row p-4">
              <div className="col-12">
                <div className="hometest">
                  <CategorySlider />
                </div>
              </div>
            </div>
            <div class="row p-20 sheller_card_image">
              <div class="col-md-12 col-12 p-10">
                <h3 class="text-white">Curated stores</h3>
              </div>
              <div class="col-md-3 col-4 p-4">
                <img src="https://m.media-amazon.com/images/G/31/img19/OOC/Gateway/ohl/halo/SBC/Curated/BestSeller._SX282_QL85_.jpg" />
              </div>
              <div class="col-md-3 col-4 p-4">
                <img src="https://m.media-amazon.com/images/G/31/img19/OOC/Gateway/ohl/halo/SBC/Curated/Best-Deal._SX282_QL85_.jpg" />
              </div>
              <div class="col-md-3 col-4 p-4">
                <img src="https://m.media-amazon.com/images/G/31/img19/OOC/Gateway/ohl/halo/SBC/Curated/Buymore._SX282_QL85_.jpg" />
              </div>
              <div class="col-md-3 col-4 p-4">
                <img src="https://m.media-amazon.com/images/G/31/img19/OOC/Gateway/ohl/halo/SBC/Curated/BestSeller._SX282_QL85_.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categorys;
