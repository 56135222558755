import React, { useEffect, useState } from "react";
// import { allapiAction } from "@/Redux/common/action";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { allapiAction } from "../../Redux/common/action";
import { Link, useNavigate } from "react-router-dom";
import { URL } from "../../Redux/common/url";
// import Link from "next/link";
function JobPortalClint() {
  // const get_home_all_pro = useSelector((state) =>
  //     state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  // );
  const navigate = useNavigate();
  const get_job_portal_all = useSelector((state) =>
    state?.allapi?.get_job_portal_all ? state?.allapi?.get_job_portal_all : {}
  );
  const get_job_category_portal_all = useSelector((state) =>
    state?.allapi?.get_job_category_portal_all
      ? state?.allapi?.get_job_category_portal_all
      : {}
  );

  console.log(get_job_category_portal_all);

  const [tokenuser, settokenuser] = useState("");
  const [tokenvendor, settokenverndorr] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const tokenvn = localStorage.getItem("access_token_vendor");
    settokenuser(token);
    settokenverndorr(tokenvn);
    dispatch(allapiAction.getjoballpageList({ page: 1, totaldoc: 8 }));
    dispatch(allapiAction.getjobCategoryallpageList({ page: 1, totaldoc: 50 }));
  }, []);

  const totalItems = get_job_category_portal_all?.docs;

  const get_home_all_pro = [
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
    { id: 1 },
  ];

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      get_home_all_pro?.length == 1
        ? 1
        : get_home_all_pro?.length > 4
          ? 4
          : get_home_all_pro?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 4
                ? 4
                : get_home_all_pro?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 3
                ? 3
                : get_home_all_pro?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            get_home_all_pro?.length == 1
              ? 1
              : get_home_all_pro?.length > 2
                ? 2
                : get_home_all_pro?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div class="jobportal">
      <div class="search-job text-center">
        <div class="container">
          <div class="row buttons_background_row">
            <div class="col-md-4 col-4">
              <button
                type="button"
                class="btn btn-success my_buttons_zise btn-block"
              >
                <b>Candidate</b>
              </button>
            </div>
            <div class="col-md-4 col-4">
              <button
                type="button"
                class="btn btn-success my_buttons_zise btn-block"
              >
                <b>Employer</b>
              </button>
            </div>
            <div class="col-md-4 col-4">
              <button
                type="button"
                class="btn btn-success my_buttons_zise btn-block"
              >
                <b>About</b>
              </button>
            </div>
          </div>
        </div>
        <div class="marqueess mt-5">
          <marquee behavior="" direction="">
          Suceess Dream job portal
          </marquee>
        </div>
        <div class="row input_padding">
          <div class="col-md-6 col-6">
            <input
              type="text"
              class="form-control"
              placeholder="What Do YOu Wont"
            />
          </div>
          <div class="col-md-6 col-6">
            <input
              type="text"
              class="form-control"
              placeholder="Location Wise"
            />
          </div>
          <div class="col-md-6 col-6">
            <input
              type="text"
              class="form-control"
              placeholder="Category Wise"
            />
          </div>
          <div class="col-md-6 col-6">
            <input type="button" class="btn btn-primary" value="Search" />
          </div>
        </div>
      </div>
      <div class="banner text-center"></div>

      <section id="recruiters" style={{ marginBottom: "50px" }}>
        <div class="container">
          <div class="row">
            <div class="col-12 ">
              <div class="row">
                <div class="col-12">
                  <h4 class="text-center">Job Categories</h4>
                </div>
                <div class="col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="utf_job_category_area"
        class="utf_job_category_area"
        style={{ marginTop: "-230px", paddingBottom: "0%" }}
      >
        <div class="container text-center">
          {totalItems?.length > 0 && (
            <Slider {...settings}>
              {totalItems?.map((data) => {
                return (
                  <div class="p-7">
                    <a href="browse-job.html" title="">
                      <div class="utf_category_box_area">
                        <div class="utf_category_desc">
                          <div class="utf_category_icon">
                            {" "}
                            <img
                              src={URL?.API_BASE_URL + data?.image}
                              alt=""
                            />{" "}
                          </div>
                          <div class="category-detail utf_category_desc_text">
                            <h4>{data?.name}</h4>
                            {/* <p>122 Jobs</p> */}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              })}
            </Slider>
          )}
          {/* <div class="row">
            <div class="col-md-3 col-sm-6">
              <a href="browse-job.html" title="">
                <div class="utf_category_box_area">
                  <div class="utf_category_desc">
                    <div class="utf_category_icon">
                      {" "}
                      <img
                        src="https://creatosoftware.com/public/uploads/logo.png"
                        alt=""
                      />{" "}
                    </div>
                    <div class="category-detail utf_category_desc_text">
                      <h4>Web &amp; Software Dev</h4>
                      <p>122 Jobs</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-3 col-sm-6">
              <a href="browse-job.html" title="">
                <div class="utf_category_box_area">
                  <div class="utf_category_desc">
                    <div class="utf_category_icon">
                      {" "}
                      <i class="icon-tools abs-icon"></i>{" "}
                    </div>
                    <div class="category-detail utf_category_desc_text">
                      <h4>Data Science &amp; Analytics</h4>
                      <p>155 Jobs</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-3 col-sm-6">
              <a href="browse-job.html" title="">
                <div class="utf_category_box_area">
                  <div class="utf_category_desc">
                    <div class="utf_category_icon">
                      {" "}
                      <i class="ti-briefcase abs-icon"></i>{" "}
                    </div>
                    <div class="category-detail utf_category_desc_text">
                      <h4>Accounting &amp; Consulting</h4>
                      <p>300 Jobs</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-md-3 col-sm-6">
              <a href="browse-job.html" title="">
                <div class="utf_category_box_area">
                  <div class="utf_category_desc">
                    <div class="utf_category_icon">
                      {" "}
                      <i class="ti-ruler-pencil abs-icon"></i>{" "}
                    </div>
                    <div class="category-detail utf_category_desc_text">
                      <h4>Writing &amp; Translations</h4>
                      <p>80 Jobs</p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div> */}
        </div>
      </section>

      {/* <div class="container-fluid" style={{ marginTop: "-90px" }}>
        <div className="container mb-15">
          <div class="row ltjob">
            <div class="col-md-9 col-sm-6 col-6">
              <h3 class="jobb">Latest Jobs</h3>
            </div>
            <div class="col-md-3 col-sm-6 col-6 view-btn">
              <Link
                href="/all-job-list"
                class="btn theme-btn btn-m width-170 btn-success view-btn"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row  m-2">
              {get_job_portal_all?.docs &&
                get_job_portal_all?.docs?.map((data, index) => {
                  console.log(data);
                  return (
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3  ">
                      <div class="row">
                        <div class="col-12">
                          <div class="row m-3 ggg">
                            <div class="col-6 p-3">
                              <p class="bg-light text-center border ">
                                {data?.job_type}
                              </p>
                            </div>
                            <div class="col-6 p-3">
                              <button
                                type="button"
                                class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart"
                              ></button>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-6 text-center">
                              <div class=" hhh p-1">
                                <img
                                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                                  class="img-fluid rounded-circle"
                                  width="110"
                                />
                              </div>
                            </div>
                            <div class="col-3"></div>
                            <div class="col-12 mt-2 text-center">
                              <h4>{data?.job_title}</h4>
                            </div>
                            <div class="col-12 p-0 text-center text-muted">
                              <p>{data?.company_name}</p>
                            </div>
                            <div class="col-12 text-center">
                              <button
                                type="button"
                                class="btn btn-outline-success"
                              >
                                APPLY NOW
                              </button>
                            </div>
                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="row">
                  <div class="col-12">
                    <div class="row m-3 ggg">
                      <div class="col-6 p-3">
                        <p class="bg-light text-center border ">FULL TIME</p>
                      </div>
                      <div class="col-6 p-3">
                        <button
                          type="button"
                          class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart"
                        ></button>
                      </div>
                      <div class="col-3"></div>
                      <div class="col-6 text-center">
                        <div class=" hhh p-1">
                          <img
                            src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                            class="img-fluid rounded-circle"
                            width="110"
                          />
                        </div>
                      </div>
                      <div class="col-3"></div>
                      <div class="col-12 mt-2 text-center">
                        <h4>Product Redesign</h4>
                      </div>
                      <div class="col-12 p-0 text-center text-muted">
                        <p>2708 Scenic Way, IL 62373</p>
                      </div>
                      <div class="col-12 text-center">
                        <button type="button" class="btn btn-outline-success">
                          APPLY NOW
                        </button>
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3 ">
                <div class="row">
                  <div class="col-12">
                    <div class="row m-3 ggg">
                      <div class="col-6 p-3">
                        <p class="bg-light text-center border ">FULL TIME</p>
                      </div>
                      <div class="col-6 p-3">
                        <button
                          type="button"
                          class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart"
                        ></button>
                      </div>
                      <div class="col-3"></div>
                      <div class="col-6 text-center">
                        <div class=" hhh p-1">
                          <img
                            src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                            class="img-fluid rounded-circle"
                            width="110"
                          />
                        </div>
                      </div>
                      <div class="col-3"></div>
                      <div class="col-12 mt-2 text-center">
                        <h4>Product Redesign</h4>
                      </div>
                      <div class="col-12 p-0 text-center text-muted">
                        <p>2708 Scenic Way, IL 62373</p>
                      </div>
                      <div class="col-12 text-center">
                        <button type="button" class="btn btn-outline-success">
                          APPLY NOW
                        </button>
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="row">
                  <div class="col-12">
                    <div class="row m-3 ggg">
                      <div class="col-6 p-3">
                        <p class="bg-light text-center border ">FULL TIME</p>
                      </div>
                      <div class="col-6 p-3">
                        <button
                          type="button"
                          class="btn btn-outline-danger p-2 m-1 rounded-circle float-right fa fa-heart"
                        ></button>
                      </div>
                      <div class="col-3"></div>
                      <div class="col-6 text-center">
                        <div class=" hhh p-1">
                          <img
                            src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                            class="img-fluid rounded-circle"
                            width="110"
                          />
                        </div>
                      </div>
                      <div class="col-3"></div>
                      <div class="col-12 mt-2 text-center">
                        <h4>Product Redesign</h4>
                      </div>
                      <div class="col-12 p-0 text-center text-muted">
                        <p>2708 Scenic Way, IL 62373</p>
                      </div>
                      <div class="col-12 text-center">
                        <button type="button" class="btn btn-outline-success">
                          APPLY NOW
                        </button>
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="container">
        <div className="container mb-15">
          <div class="row ltjob">
            <div class="col-md-9 col-sm-6 col-6">
              <h3 class="jobb">Latest Jobs</h3>
            </div>
            <div class="col-md-3 col-sm-6 col-6 view-btn">
              <Link
                to="#"
                class="btn theme-btn btn-m width-170 btn-success view-btn"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
        <div class="row">
          {get_job_portal_all?.docs &&
            get_job_portal_all?.docs?.map((data, i) => {
              return (
                <div class="col-md-3 col-6 p-10">
                  <div class="card ggg">
                    <p class="bg-light text-center border"> {data?.job_type}</p>
                    <div class="d-flex justify-content-center">
                      <img
                        src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                        class="img-fluid rounded-circle all_jobs_image"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h6 class="text-center">{data?.job_title}</h6>
                    <h6 class="job_date_size">{data?.company_name}</h6>
                    <div class="row">
                      <div class="col-md-12 p-4 text-center mt-2 mb-5">
                        {tokenvendor ? (
                          <></>
                        ) : (
                          <button
                            type="button"
                            class="btn btn-outline-success"
                            onClick={() => {
                              if (tokenuser) {
                                navigate("/job-apply/" + data?._id);
                              } else {
                                navigate("/login");
                              }
                            }}
                          >
                            APPLY NOW
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          {/* <div class="col-md-3 col-6 p-10">
            <div class="card ggg">
              <p class="bg-light text-center border">Full Time</p>
              <div class="d-flex justify-content-center">
                <img
                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                  class="img-fluid rounded-circle all_jobs_image"
                  style={{ width: "40%" }}
                />
              </div>
              <h6 class="text-center">Compounder</h6>
              <h6 class="job_date_size">2708 Scenic Way, IL 62373</h6>
              <div class="row">
                <div class="col-md-12 p-4 text-center mt-2 mb-5">
                  <button type="button" class="btn btn-outline-success">
                    APPLY NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-6 p-10">
            <div class="card ggg">
              <p class="bg-light text-center border">Full Time</p>
              <div class="d-flex justify-content-center">
                <img
                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                  class="img-fluid rounded-circle all_jobs_image"
                  style={{ width: "40%" }}
                />
              </div>
              <h6 class="text-center">Compounder</h6>
              <h6 class="job_date_size">2708 Scenic Way, IL 62373</h6>
              <div class="row">
                <div class="col-md-12 p-4 text-center mt-2 mb-5">
                  <button type="button" class="btn btn-outline-success">
                    APPLY NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-6 p-10">
            <div class="card ggg">
              <p class="bg-light text-center border">Full Time</p>
              <div class="d-flex justify-content-center">
                <img
                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                  class="img-fluid rounded-circle all_jobs_image"
                  style={{ width: "40%" }}
                />
              </div>
              <h6 class="text-center">Compounder</h6>
              <h6 class="job_date_size">2708 Scenic Way, IL 62373</h6>
              <div class="row">
                <div class="col-md-12 p-4 text-center mt-2 mb-5">
                  <button type="button" class="btn btn-outline-success">
                    APPLY NOW
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="container mb-15">
          <div class="row ltjob">
            <div class="col-md-9 col-sm-6 col-6">
              <h3 class="jobb">All Jobs</h3>
            </div>
            <div class="col-md-3 col-sm-6 col-6 view-btn">
              <Link
                to="#"
                class="btn theme-btn btn-m width-170 btn-success view-btn"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
        <div class="row">
          {get_job_portal_all?.docs &&
            get_job_portal_all?.docs?.map((data, i) => {
              return (
                <div class="col-md-3 col-6 p-10">
                  <div class="card ggg">
                    <p class="bg-light text-center border"> {data?.job_type}</p>
                    <div class="d-flex justify-content-center">
                      <img
                        src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                        class="img-fluid rounded-circle all_jobs_image"
                        style={{ width: "40%" }}
                      />
                    </div>
                    <h6 class="text-center">{data?.job_title}</h6>
                    <h6 class="job_date_size">{data?.company_name}</h6>
                    <div class="row">
                      <div class="col-md-12 p-4 text-center mt-2 mb-5">
                        {tokenvendor ? (
                          <></>
                        ) : (
                          <button
                            type="button"
                            class="btn btn-outline-success"
                            onClick={() => {
                              if (tokenuser) {
                                navigate("/job-apply/" + data?._id);
                              } else {
                                navigate("/login");
                              }
                            }}
                          >
                            APPLY NOW
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div class="col-md-3 col-6 p-10">
            <div class="card ggg">
              <p class="bg-light text-center border">Full Time</p>
              <div class="d-flex justify-content-center">
                <img
                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                  class="img-fluid rounded-circle all_jobs_image"
                  style={{ width: "40%" }}
                />
              </div>
              <h6 class="text-center">Compounder</h6>
              <h6 class="job_date_size">2708 Scenic Way, IL 62373</h6>
              <div class="row">
                <div class="col-md-12 p-4 text-center mt-2 mb-5">
                  <button type="button" class="btn btn-outline-success">
                    APPLY NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-6 p-10">
            <div class="card ggg">
              <p class="bg-light text-center border">Full Time</p>
              <div class="d-flex justify-content-center">
                <img
                  src="https://utouchdesign.com/themes/envato/escort/assets/img/company_logo_1.png"
                  class="img-fluid rounded-circle all_jobs_image"
                  style={{ width: "40%" }}
                />
              </div>
              <h6 class="text-center">Compounder</h6>
              <h6 class="job_date_size">2708 Scenic Way, IL 62373</h6>
              <div class="row">
                <div class="col-md-12 p-4 text-center mt-2 mb-5">
                  <button type="button" class="btn btn-outline-success">
                    APPLY NOW
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div class="row">
          <div class="col-md-12 mt-15">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="bg-info text-white">
                      <b>JOB</b>
                    </th>
                    <th class="bg-info text-white">
                      <b>COMPANEY</b>
                    </th>
                    <th class="bg-info text-white">
                      <b>LOCATION</b>
                    </th>
                    <th class="bg-info text-white">
                      <b>STATUS</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {get_job_portal_all?.docs &&
                    get_job_portal_all?.docs?.map((data, i) => {
                      return (
                        <tr>
                          <td>John</td>
                          <td>Doe</td>
                          <td>john</td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-success apply_button"
                            >
                              Appy
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  {/* <tr>
                    <td>Mary</td>
                    <td>Moe</td>
                    <td>mary</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-success apply_button"
                      >
                        Appy
                      </button>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-md-10 col-8 view-btn"></div>
          <div class="col-md-2 col-4 view-btn mb-15">
            <Link
              to="#"
              class="btn theme-btn btn-m width-170 btn-success view-btn"
            >
              View All
            </Link>
          </div>
        </div>
      </div>
      {/* <section id="site-stats">
        <div class="container text-center">
          <h3>JOBSITE STATS</h3>
          <div class="container padding">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/bologna-1.jpg"
                    alt="Bologna"
                  />
                  <div class="card-body">
                    <h4 class="card-title">Bologna</h4>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Emilia-Romagna Region, Italy
                    </h6>
                    <p class="card-text">
                      It is the seventh most populous city in Italy, at the
                      heart of a metropolitan area of about one million people.{" "}
                    </p>
                    <a href="#" class="card-link">
                      Read More
                    </a>
                    <a href="#" class="card-link">
                      Book a Trip
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/bologna-1.jpg"
                    alt="Bologna"
                  />
                  <div class="card-body">
                    <h4 class="card-title">Bologna</h4>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Emilia-Romagna Region, Italy
                    </h6>
                    <p class="card-text">
                      It is the seventh most populous city in Italy, at the
                      heart of a metropolitan area of about one million people.{" "}
                    </p>
                    <a href="#" class="card-link">
                      Read More
                    </a>
                    <a href="#" class="card-link">
                      Book a Trip
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/bologna-1.jpg"
                    alt="Bologna"
                  />
                  <div class="card-body">
                    <h4 class="card-title">Bologna</h4>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Emilia-Romagna Region, Italy
                    </h6>
                    <p class="card-text">
                      It is the seventh most populous city in Italy, at the
                      heart of a metropolitan area of about one million people.{" "}
                    </p>
                    <a href="#" class="card-link">
                      Read More
                    </a>
                    <a href="#" class="card-link">
                      Book a Trip
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="card">
                  <img
                    class="card-img-top"
                    src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/bologna-1.jpg"
                    alt="Bologna"
                  />
                  <div class="card-body">
                    <h4 class="card-title">Bologna</h4>
                    <h6 class="card-subtitle mb-2 text-muted">
                      Emilia-Romagna Region, Italy
                    </h6>
                    <p class="card-text">
                      It is the seventh most populous city in Italy, at the
                      heart of a metropolitan area of about one million people.{" "}
                    </p>
                    <a href="#" class="card-link">
                      Read More
                    </a>
                    <a href="#" class="card-link">
                      Book a Trip
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default JobPortalClint;
