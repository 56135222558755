import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Tooltip } from "antd";
import Box3 from "./Box3";
// import HeadingBack from "../../HeadingBack";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
import HeadingBack from "../HeadingBack";
// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
import ImageUploading from "react-images-uploading";

function SettingCom() {
  const [show, setShow] = useState(false);
  const [showPro, setShowPro] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [showOtpBoxsata, setShowOtpBoxdata] = useState("");
  const dispatch = useDispatch();
  //   const [showOtpBox, setShowOtpBox] = useState<any>(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );
  const get_vendor_profiles = useSelector((state) =>
    state.allapi.get_vendor_profiles ? state.allapi.get_vendor_profiles : {}
  );

  console.log(get_vendor_profiles);

  const [userDetailsEdit, setUserDetailsEdit] = useState({});

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [userKycEdit, setUserKycEdit] = useState({});
  const [images1, setImages1] = React.useState([]);

  const onChange1 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages1(imageList);
  };
  const [images2, setImages2] = React.useState([]);

  const onChange2 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages2(imageList);
  };
  const [images3, setImages3] = React.useState([]);

  const onChange3 = (imageList, addUpdateIndex) => {
    // data for submit

    setImages3(imageList);
  };

  const [imagesb, setImagesb] = useState([]);
  useEffect(() => {
    setUserDetailsEdit(get_vendor_profiles);
    setUserKycEdit(get_vendor_profiles?.kyc_id);

    // setImages
    // if (get_vendor_profiles?.profile_image) {
    //   setImagesPro([
    //     { data_url: URL.API_BASE_URL + get_vendor_profiles?.profile_image },
    //   ]);
    // }
    if (get_vendor_profiles?.kyc_id?.bankpassbook) {
      setImagesb([
        {
          data_url:
            URL.API_BASE_URL + get_vendor_profiles?.kyc_id?.bankpassbook,
        },
      ]);
    }
    if (get_vendor_profiles?.kyc_id?.aadharfrontImage) {
      setImages1([
        {
          data_url:
            URL.API_BASE_URL + get_vendor_profiles?.kyc_id?.aadharfrontImage,
        },
      ]);
    }
    if (get_vendor_profiles?.kyc_id?.aadharbacktImage) {
      setImages2([
        {
          data_url:
            URL.API_BASE_URL + get_vendor_profiles?.kyc_id?.aadharbacktImage,
        },
      ]);
    }
    if (get_vendor_profiles?.kyc_id?.pancardImage) {
      setImages3([
        {
          data_url:
            URL.API_BASE_URL + get_vendor_profiles?.kyc_id?.pancardImage,
        },
      ]);
    }
  }, [get_vendor_profiles]);
  async function validateIFSC(ifsc) {
    const apiUrl = `https://ifsc.razorpay.com/${ifsc}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (response.ok) {
        // Check if the bank returned from API matches the expected bank
        // if (data.BANK.toLowerCase() == userKycEdit?.bank_name) {
        //   return true; // IFSC is valid and matches the expected bank
        // } else {
        //   return false; // IFSC is valid but does not match the expected bank
        // }
        return true;
      } else {
        return false; // Error in API response
      }
    } catch (error) {
      console.error("Error:", error);
      return false; // Error during API call
    }
  }
  function validatePanCard(panNumber) {
    // Regular expression to match PAN card pattern
    var upperPanNumber = panNumber.toUpperCase();
    var panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    // Check if the provided panNumber matches the pattern
    return panRegex.test(upperPanNumber);
  }
  function validateAadhar(aadhar) {
    // Aadhar number format: 12 digits
    var aadharRegex = /^\d{12}$/;

    if (aadharRegex.test(aadhar)) {
      return true; // Valid Aadhar number
    } else {
      return false; // Invalid Aadhar number
    }
  }

  const hendleToupdate1 = async () => {
    // edituserProfileByuser

    if (!userKycEdit?.ifsc) {
      toast.error("please enter ifsc code ");
      return;
    }
    const resp = await validateIFSC(userKycEdit?.ifsc);
    if (!resp) {
      toast.error("invalid ifsc code and bank name");
      return;
    }
    if (!userKycEdit?.bank_name) {
      toast.error("please enter bank name ");
      return;
    }
    if (!userKycEdit?.acc_no) {
      toast.error("please enter account number");
      return;
    }

    if (!userKycEdit?.aadhar_card) {
      toast.error("please enter aadhar card number");
      return;
    }

    if (!validateAadhar(userKycEdit?.aadhar_card)) {
      // console.log("Valid Aadhar number.");
      toast.error("Invalid Aadhar number.");
      return;
    }

    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1?.length == 0) {
      // roledata?.role_name

      toast.error("please select aadhar front Image");
      return;
      // data.append("aadharfrontImage", images1[0]?.file);
    }
    if (images2?.length == 0) {
      // roledata?.role_name
      toast.error("please select Aadhar Back Image");
      return;
      // data.append("aadharbacktImage", images2[0]?.file);
    }

    if (!userKycEdit?.pan_card) {
      toast.error("please enter pan card number");
      return;
    }
    if (!validatePanCard(userKycEdit?.pan_card)) {
      toast.error("invalid pancard");
      return;
    }
    if (images3?.length == 0) {
      // roledata?.role_name
      toast.error("please select pan card Image");
      return;
      // data.append("pancardImage", images3[0]?.file);
    }
    const data = new FormData();
    data.append("pan_card", userKycEdit?.pan_card);
    data.append("aadhar_card", userKycEdit?.aadhar_card);
    // data.append("acc_no", userKycEdit?.acc_no);
    // data.append("ifsc", userKycEdit?.ifsc);
    // data.append("_id", userKycEdit?._id);
    if (images1[0]?.file) {
      // roledata?.role_name
      data.append("aadharfrontImage", images1[0]?.file);
    }
    if (images2[0]?.file) {
      // roledata?.role_name
      data.append("aadharbacktImage", images2[0]?.file);
    }
    if (images3[0]?.file) {
      // roledata?.role_name
      data.append("pancardImage", images3[0]?.file);
    }

    // const data = new FormData();
    data.append("branch_name", userKycEdit?.branch_name);
    data.append("bank_name", userKycEdit?.bank_name);
    data.append("acc_no", userKycEdit?.acc_no);
    data.append("ifsc", userKycEdit?.ifsc);
    data.append("_id", userKycEdit?._id);
    if (images?.length == 0) {
      toast.error("please select bank passbook");
      return;
    }
    if (imagesb[0]?.file) {
      // roledata?.role_name
      data.append("bankpassbook", imagesb[0]?.file);
    }
    const response = await dispatch(allapiAction.edituserKycByUser(data));
    if (response?.success) {
      dispatch(allapiAction.getprofilevendor({}));
      setShow1(false);
    }
  };

  // const [images, setImages] = React.useState([]);
  // const maxNumber = 1;
  const hendleToprofileEdit1 = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };

  const [userBuss, setuserBuss] = useState({});
  const [images, setImages] = useState([]);

  const maxNumber = 1;

  useEffect(() => {
    if (get_vendor_profiles) {
      // URL.API_BASE_URL + get_vendor_profiles?.sellerProfile
      setImages([
        { data_url: URL.API_BASE_URL + get_vendor_profiles?.sellerProfile },
      ]);
      setuserBuss(get_vendor_profiles);
    }
  }, [get_vendor_profiles]);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };
  const onChangeb = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImagesb(imageList);
  };

  useEffect(() => {
    setStartDate(new Date(get_setting?.withdrawalstarttime));
    setStartDate2(new Date(get_setting?.withdrawalendtime));
    // setuserBuss(get_setting);
  }, []);

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setuserBuss({ ...userBuss, [name]: value });
  };
  useEffect(() => {
    // dispatch(adminAction.getInvoceDetails({}))
    dispatch(allapiAction.getprofilevendor({}));

    return () => {};
  }, []);

  const submitProduct = () => {
    try {
      if (!userBuss?.fullName) {
        toast.error("please enter full name");
      } else if (!userBuss?.displayName) {
        toast.error("please enter business name");
      } /* else if (!userBuss?.withdrwalcommission) {
        toast.error("please enter withdrwal commission");
      } else if (!userBuss?.maxmumwithdrwal) {
        toast.error("please enter maxmum withdrwal");
      } else if (!userBuss?.minimumwithdrwal) {
        toast.error("please enter minimum withdrwal");
      } else if (!userBuss?.minimumrecharge) {
        toast.error("please enter minimum recharge");
      } else if (!userBuss?.maxmumrecharge) {
        toast.error("please enter maxmum recharge");
      } */ else {
        // setLoading(true);
        // gstNo
        // companyName
        // email
        // address

        // data.append('gstNo', userBuss?.gstNo)
        // data.append('companyName', userBuss?.companyName)
        // data.append('email', userBuss?.email)
        // data.append('address', userBuss?.address)
        // data.append('fb', userBuss?.fb)
        // data.append('insta', userBuss?.insta)
        // data.append('twitter', userBuss?.twitter)
        // data.append('youtube', userBuss?.youtube)
        // data.append('number', userBuss?.number)
        // data.append('pinterst', userBuss?.pinterst)

        // data.append('invoiceLogo', images[0]?.file)

        // userBuss.withdrawalstarttime = startDate;
        // userBuss.withdrawalendtime = startDate2;

        // console.log(startDate);
        // console.log(startDate2);

        dispatch(allapiAction.updateBussProfileByvendor(userBuss));
        // setShow(false);
        // setShowOtpBox(true)
        setShowOtpBox(false);
        setShow(false);
        // setLoading(false);
      }

      // setCurrentUser(user)
    } catch (error) {
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };

 

  //   updateBussLogovendor
  //   const submitChangeVerify2 = () => {
  //     if (!showOtpBoxsata) {
  //       toast.error('please enter otp')
  //     } else if (showOtpBoxsata?.length < 6 || showOtpBoxsata?.length > 6) {
  //       toast.error('otp invalid')
  //     } else {
  //       dispatch(
  //         allapiAction.verifyWhatsapp({
  //           whatsappNumber: userBuss?.whatsappNumber,
  //           otp: showOtpBoxsata,
  //         })
  //       )
  //       setShowOtpBox(false)
  //       setShow(false)
  //     }
  //   }

  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Shop"}
            // subHeading={`Total ${get_transaction_list?.totalDocs || 0} Transaction`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Update">
            <span className="jop_btn" onClick={handleShow}>
              Update
            </span>
          </Tooltip>
        </div>
      </div>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update Bank Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label for="product_name" className="form-label">
                  Bank passbook
                </label>
                <ImageUploading
                  // multiple
                  value={imagesb}
                  onChange={onChangeb}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded mb-2"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
              <div className="mb-3">
                <label for="title" className="form-label">
                  IFSC
                </label>
                <input
                  name="ifsc"
                  className="form-control"
                  placeholder="Enter ifsc"
                  type="text"
                  value={userKycEdit?.ifsc}
                  onChange={(e) => {
                    hendleToprofileEdit1(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="bank_name" className="form-label">
                  bank name
                </label>
                <input
                  name="bank_name"
                  className="form-control"
                  placeholder="Enter bank_name"
                  type="text"
                  value={userKycEdit?.bank_name}
                  onChange={(e) => {
                    hendleToprofileEdit1(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="title" className="form-label">
                  branch name
                </label>
                <input
                  name="branch_name"
                  className="form-control"
                  placeholder="Enter branch_name"
                  type="text"
                  value={userKycEdit?.branch_name}
                  onChange={(e) => {
                    hendleToprofileEdit1(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="acc_no" className="form-label">
                  Account no
                </label>
                <input
                  name="acc_no"
                  className="form-control"
                  placeholder="Enter Account No."
                  type="text"
                  value={userKycEdit?.acc_no}
                  onChange={(e) => {
                    hendleToprofileEdit1(e);
                  }}
                />
              </div>

              {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div> */}
            </div>
          </div>
          <div className="card mx-auto ">
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label for="title" className="form-label">
                  Aadhar Card No.
                </label>
                <input
                  name="aadhar_card"
                  className="form-control"
                  placeholder="Enter aadhar card"
                  type="text"
                  value={userKycEdit?.aadhar_card}
                  onChange={(e) => {
                    hendleToprofileEdit1(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="product_name" className="form-label">
                  Addhar Front Photo
                </label>
                <ImageUploading
                  // multiple
                  value={images1}
                  onChange={onChange1}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded mb-2"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
              <div className="mb-3">
                <label for="product_name" className="form-label">
                  Aadhar back Photo
                </label>
                <ImageUploading
                  // multiple
                  value={images2}
                  onChange={onChange2}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded mb-2"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
              <div className="mb-3">
                <label for="pan_card" className="form-label">
                  Pan card
                </label>
                <input
                  name="pan_card"
                  className="form-control"
                  placeholder="Enter pan_card"
                  type="text"
                  value={userKycEdit?.pan_card}
                  onChange={(e) => {
                    hendleToprofileEdit1(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label for="product_name" className="form-label">
                  Pan Card Photo
                </label>
                <ImageUploading
                  // multiple
                  value={images3}
                  onChange={onChange3}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        className="btn btn-primary btn-sm rounded"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Click or Drop here
                      </button>
                      &nbsp;
                      {/* <button
                          className="btn btn-primary btn-sm rounded"
                          onClick={onImageRemoveAll}
                        >
                          Remove all images
                        </button> */}
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image["data_url"]} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-primary btn-sm rounded mb-2"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary btn-sm rounded"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>

              {/* <div className="mb-3">
                  <label for="teg" className="form-label">
                    Product Tag
                  </label>
                  <input
                    name="tegs"
                    className="form-control"
                    placeholder="Enter tag"
                    type="text"
                    onChange={(e) => {
                      hendleChangerole(e);
                    }}
                  />
                </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="ms-2"
            onClick={() => {
              hendleToupdate1();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">Name</label>
                <input
                  name="fullName"
                  className="form-control"
                  placeholder="Enter full Name"
                  type="text"
                  value={userBuss?.fullName}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Business Name</label>
                <input
                  name="displayName"
                  className="form-control"
                  placeholder="Enter business Name"
                  type="text"
                  value={userBuss?.displayName}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              {/* <div className="mb-3">
                <label className="form-label">game commision</label>
                <input
                  name="gamecommision"
                  className="form-control"
                  placeholder="Enter game commision"
                  type="number"
                  value={userBuss?.gamecommision}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">withdrwal commission</label>
                <input
                  name="withdrwalcommission"
                  className="form-control"
                  placeholder="Enter withdrwal commission"
                  type="number"
                  value={userBuss?.withdrwalcommission}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">minimum withdrawal</label>
                <input
                  name="minimumwithdrwal"
                  className="form-control"
                  placeholder="Enter  minimum withdrawal"
                  type="number"
                  value={userBuss?.minimumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum withdrawal</label>
                <input
                  name="maxmumwithdrwal"
                  className="form-control"
                  placeholder="Enter  maxmum withdrawal"
                  type="number"
                  value={userBuss?.maxmumwithdrwal}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Minimum recharge</label>
                <input
                  name="minimumrecharge"
                  className="form-control"
                  placeholder="Enter minimum recharge"
                  type="number"
                  value={userBuss?.minimumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">maxmum recharge</label>
                <input
                  name="maxmumrecharge"
                  className="form-control"
                  placeholder="Enter maxmum recharge"
                  type="number"
                  value={userBuss?.maxmumrecharge}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Vpa</label>
                <input
                  name="vpa"
                  className="form-control"
                  placeholder="Enter vpa"
                  type="text"
                  value={userBuss?.vpa}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">name</label>
                <input
                  name="name"
                  className="form-control"
                  placeholder="Enter name"
                  type="text"
                  value={userBuss?.name}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">merchant Code</label>
                <input
                  name="merchantCode"
                  className="form-control"
                  placeholder="Enter  merchant Code"
                  type="text"
                  value={userBuss?.merchantCode}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                />
              </div> */}
            </div>
            {/* ) : ( */}
            {/* <div className='card-body'>
                

                <div className='mb-3'>
                  <label className='form-label'>Business Name</label>
                  <input
                    name='businessName'
                    className='form-control'
                    placeholder='Enter Business name'
                    type='text'
                    value={showOtpBoxsata}
                    onChange={(e) => {
                      setShowOtpBoxdata(e.target.value)
                    }}
                  />
                </div>
              </div> */}
            {/* )} */}
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProduct();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
      <div className="mt-30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-12">
              <Box3 />
            </div>
            <div className="col-md-6 col-12">
              <div className="dashboardBoxShad">
                <div className="row">
                  <div className="col-4">
                    <h4>Account Number</h4>
                  </div>
                  <div className="col-8">
                    <p>{get_vendor_profiles?.kyc_id?.acc_no}</p>
                  </div>
                  <div className="col-4">
                    <h4>IFSC</h4>
                  </div>
                  <div className="col-8">
                    <p>{get_vendor_profiles?.kyc_id?.ifsc}</p>
                  </div>
                  <div className="col-4">
                    <h4>Bank Name</h4>
                  </div>
                  <div className="col-8">
                    <p>{get_vendor_profiles?.kyc_id?.bank_name}</p>
                  </div>
                  <div className="col-4">
                    <h4>branch Name</h4>
                  </div>
                  <div className="col-8">
                    <p>{get_vendor_profiles?.kyc_id?.branch_name}</p>
                  </div>
                  <div className="col-4">
                    <h4>bank passbook</h4>
                  </div>
                  <div className="col-8">
                    <img
                      width={100}
                      src={
                        URL?.API_BASE_URL +
                        get_vendor_profiles?.kyc_id?.bankpassbook
                      }
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                  <div className="col-4">
                    <h4>Addhar Number</h4>
                  </div>
                  <div className="col-8">
                    <p>{get_vendor_profiles?.kyc_id?.aadharfrontImage}</p>
                  </div>
                  <div className="col-4">
                    <img
                      width={100}
                      src={
                        URL?.API_BASE_URL +
                        get_vendor_profiles?.kyc_id?.aadharbacktImage
                      }
                      alt=""
                    />
                  </div>

                  <div className="col-8">
                    <img
                      width={100}
                      src={
                        URL?.API_BASE_URL +
                        get_vendor_profiles?.kyc_id?.bankpassbook
                      }
                      alt=""
                    />
                  </div>
                  <div className="col-12">
                    <hr />
                  </div>
                  <div className="col-4">
                    <h4>Pan Card Number</h4>
                  </div>
                  <div className="col-8">
                    <p>{get_vendor_profiles?.kyc_id?.pan_card}</p>
                  </div>
                  <div className="col-4">
                    <h4>Pan Card Photo</h4>
                  </div>
                  <div className="col-8">
                    <img
                      width={100}
                      src={
                        URL?.API_BASE_URL +
                        get_vendor_profiles?.kyc_id?.pancardImage
                      }
                      alt=""
                    />
                  </div>
                </div>
                <button
                  onClick={() => handleShow1()}
                  className="jop_btn ant-tooltip-open"
                >
                  {" "}
                  Update{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingCom;
