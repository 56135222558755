import { Grid, Layout } from "antd";
import React, { useEffect, useState } from "react";
import Sidebar from "../../Layout2/Sidebar";
import TopBar from "../../Layout2/TopBar";

 
 
// import ListingCom from "./ListingCom";
// import Sidebar from "../../../../Component/Layout2/Sidebar";
// import TopBar from "../../../../Component/Layout2/TopBar";

// import ListingCom from "../../../../Component/Seller/Listing/ListingCom";

const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;
function SellerJobAddScreen() {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);


  useEffect(() => {
    setCollapsed(!screens.md);
    return () => {};
  }, [screens]);
  return (
    <div>
      <Layout className="layout_css">
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <Header className="site-layout-backgroundr" style={{ padding: 0 }}>
            <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "6px",
              padding: 10,
              minHeight: 280,
              overflowY: "auto",
            }}
          >
 
          </Content>
          {/* <Footer></Footer> */}
        </Layout>
      </Layout>
    </div>
  );
}

export default SellerJobAddScreen;
