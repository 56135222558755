// import Image from "next/image";
import React, { useLayoutEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

function CoustomImg({ url, altkey, w1, w2, h1, h2 }) {
  const [windowWidth, setWindowWidth] = useState(600);
  const [isLoaded, setIsLoaded] = useState(false);
  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageWidth = windowWidth <= 767 ? w1 : w2;
  const imageHeight = windowWidth <= 767 ? h1 : h2;
  return (
    <div>
      {/* <Image
        className="default-img proimg"
        src={url}
        alt={altkey}
        width={imageWidth}
        height={imageHeight}
        // layout="responsive"
      /> */}

<img
        src={url}
        alt="loading..."
        className={`blur-image ${isLoaded ? "hide" : ""}`}
        style={{
          filter: "blur(20px)",
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 0 : 1,
        }}
      />
      <LazyLoadImage
        alt={altkey}
        width={imageWidth}
        height={imageHeight}
        delayTime={300}
        onLoad={() => setIsLoaded(true)}
        style={{
          // Hide until loaded
          transition: "opacity 0.3s ease-in-out",
          opacity: isLoaded ? 1 : 0,
        }}
        src={url} // use normal <img> attributes as props
      />
    </div>
  );
}

export default CoustomImg;
