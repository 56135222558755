// import { allapiAction } from "@/Redux/common/action";
// import { URL } from "@/Redux/common/url";
// import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import HomeSlider from "../Home/HomeSlider";

function LonginComponent() {
  const slider_list = useSelector((state) =>
    state?.allapi?.slider_list ? state?.allapi?.slider_list : {}
  );

  console.log(slider_list);

  const slider = slider_list?.sliders ? slider_list?.sliders : [];
  const ad = slider_list?.ads ? slider_list?.ads : [];
  const banner = slider_list?.banner ? slider_list?.banner : [];
  const ads2 = [...ad].reverse();

  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [userData, setuserData] = useState({ phone: "", password: "" });
  const [type, settype] = useState("User");
  const dispatch = useDispatch();
  const router = useNavigate();

  const hendletochange = (e) => {
    const { name, value } = e?.target;
    setuserData({ ...userData, [name]: value });
  };

  const hendletoSubmit = (e) => {
    e.preventDefault();

    if (!userData?.phone) {
      toast.error("please enter phone number");
    } else if (!userData?.password) {
      toast.error("please enter password");
    } else {
      userData.role_name = type;
      dispatch(allapiAction.signinSeller(userData, router));
      return () => {};
    }
  };

  useEffect(() => {
    dispatch(allapiAction.getsliderList({ page: "loginpage" }));

    return () => {};
  }, []);

  return (
    <div>
      {" "}
      <div className="hometest">
        <HomeSlider />
      </div>
      <div className="ps-page--my-account">
        {/* <div className="ps-breadcrumb">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li>My account</li>
            </ul>
          </div>
        </div> */}
        <div className="ps-my-account">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                <form className="ps-form--account ps-tab-root">
                  <div className="ps-tabs">
                    <div className="ps-tab active" id="sign-in">
                      <ul className="ps-tab-list">
                        <li
                          onClick={() => {
                            settype("User");
                          }}
                          className={
                            type == "User"
                              ? "active tbbtn  gradient-bg"
                              : "tbbtn"
                          }
                        >
                          <a href="#">Customer</a>
                        </li>
                        <li
                          className={
                            type == "Vendor"
                              ? "active tbbtn gradient-bg"
                              : " tbbtn"
                          }
                          onClick={() => {
                            settype("Vendor");
                          }}
                        >
                          <a href="#">Vendor</a>
                        </li>
                      </ul>
                      <div className="ps-form__content">
                        <h5>Log In Your Account</h5>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter phone number"
                            name="phone"
                            onChange={(e) => {
                              hendletochange(e);
                            }}
                          />
                        </div>
                        <div className="form-group form-forgot">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter password"
                            name="password"
                            onChange={(e) => {
                              hendletochange(e);
                            }}
                          />
                          {/* <a href="#">Forgot?</a> */}
                        </div>
                        {/* <div className="form-group">
                      <div className="ps-checkbox">
                        <input
                          className="form-control"
                          type="checkbox"
                          id="remember-me"
                          name="remember-me"
                        />
                        <label for="remember-me">Rememeber me</label>
                      </div>
                    </div> */}
                        <div className="form-group submtit">
                          <button
                            onClick={(e) => {
                              hendletoSubmit(e);
                            }}
                            className="ps-btn ps-btn--fullwidth"
                          >
                            Login
                          </button>
                        </div>
                        <div className="register-link">
                          <p>
                            Don't have an account?{" "}
                            <Link to="/register">Register</Link>
                          </p>
                        </div>
                      </div>
                      <div className="ps-form__footer">
                        {/* <p>Connect with:</p>
                    <ul className="ps-list--social">
                      <li>
                        <a className="facebook" href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a className="google" href="#">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li>
                        <a className="twitter" href="#">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a className="instagram" href="#">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul> */}
                      </div>
                    </div>
                    <div className="ps-tab" id="register">
                      <div className="ps-form__content">
                        <h5>Register An Account</h5>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Username or email address"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Password"
                          />
                        </div>
                        <div className="form-group submtit">
                          <button className="ps-btn ps-btn--fullwidth">
                            Login
                          </button>
                        </div>

                        <div className="register-link">
                          <p>
                            Don't have an account?{" "}
                            <Link to="/register">Register</Link>
                          </p>
                        </div>
                      </div>
                      <div className="ps-form__footer">
                        {/* <p>Connect with:</p>
                    <ul className="ps-list--social">
                      <li>
                        <a className="facebook" href="#">
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a className="google" href="#">
                          <i className="fa fa-google-plus"></i>
                        </a>
                      </li>
                      <li>
                        <a className="twitter" href="#">
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a className="instagram" href="#">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                <div className="ps-section__right row">
                  <div className="col-sm-12 col-12 adsboxcus1">
                    {ad && (
                      <Slider {...settings}>
                        {ad &&
                          ad?.map((data, i) => {
                            return (
                              <div key={i}>
                                <a className="ps-collection" href="#">
                                  <img
                                    src={URL?.API_BASE_URL + data?.image}
                                    alt=""
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </Slider>
                    )}
                  </div>
                  <div className="col-sm-12 col-12 adsboxcus1">
                    {ads2 && (
                      <Slider {...settings}>
                        {ads2 &&
                          ads2?.map((data, i) => {
                            return (
                              <div key={i}>
                                <a className="ps-collection" href="#">
                                  <img
                                    src={URL?.API_BASE_URL + data?.image}
                                    alt=""
                                  />
                                </a>
                              </div>
                            );
                          })}
                      </Slider>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="hometest">
        <HomeSlider />
      </div>
    </div>
  );
}

export default LonginComponent;
