// import { URL } from "@/Redux/common/url";
// import Link from "next/link";
// import { useRouter } from "next/router";
import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import CoustomImg from "../CoustomImg/CoustomImg";
import TruncateText from "../TruncateText/TruncateText";
import { Link, useNavigate, useParams } from "react-router-dom";
import { URL } from "../../Redux/common/url";
function LatestListing({ postList }) {
  const get_home_all_pro = useSelector((state) =>
    state?.allapi?.get_home_all_pro ? state?.allapi?.get_home_all_pro : {}
  );

  const router = useNavigate();
  const { id } = useParams();
  // const { id } = router.query;

  const reacentList = postList?.listing;
  const reacentProduct = postList?.product;

  const recentrecentProduct = get_home_all_pro ? get_home_all_pro : [];
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      reacentProduct?.length == 1
        ? 1
        : reacentProduct?.length > 6
          ? 6
          : reacentProduct?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            reacentProduct?.length == 1
              ? 1
              : reacentProduct?.length > 4
                ? 4
                : reacentProduct?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            reacentProduct?.length == 1
              ? 1
              : reacentProduct?.length > 3
                ? 3
                : reacentProduct?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            reacentProduct?.length == 1
              ? 1
              : reacentProduct?.length > 2
                ? 2
                : reacentProduct?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow:
      reacentList?.length == 1
        ? 1
        : reacentList?.length > 6
          ? 6
          : reacentList?.length - 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:
            reacentList?.length == 1
              ? 1
              : reacentList?.length > 4
                ? 4
                : reacentList?.length - 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:
            reacentList?.length == 1
              ? 1
              : reacentList?.length > 3
                ? 3
                : reacentList?.length - 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            reacentList?.length == 1
              ? 1
              : reacentList?.length > 2
                ? 2
                : reacentList?.length - 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [windowWidth, setWindowWidth] = useState(600);

  useLayoutEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div className="ps-product-list ps-clothings mt-5 px-3">
        {reacentProduct?.length > 0 && (
          <div className="ps-section__header my-5 border border-primary">
            <h3>Top Seller Product</h3>
            <Link to={"/vendor-store/product/" + id}>View All</Link>
          </div>
        )}

        <div className="ps-block__right"></div>
        {reacentProduct?.length > 0 && reacentProduct?.length > 6 ? (
          <Slider {...settings}>
            {reacentProduct &&
              reacentProduct?.map((data, i) => {
                return (
                  <div className="p-10" key={i}>
                    <div className="ps-block--category">
                      <Link
                        className="ps-block__overlay"
                        to={"/product/" + data?.pro_sulg}
                      ></Link>
                      <CoustomImg
                        url={
                          data?.featureImage && data?.featureImage !== undefined
                            ? URL?.API_BASE_URL + data?.featureImage
                            : "/img/message/logo_95new.png"
                        }
                        altkey={data?.title}
                        w1={165}
                        w2={200}
                        h1={80}
                        h2={120}
                      />
                      <p>
                        <TruncateText
                          text={data?.title}
                          maxLength={windowWidth > 600 ? 15 : 8}
                        />
                      </p>
                    </div>
                  </div>
                );
              })}
          </Slider>
        ) : (
          <div className="row">
            {reacentProduct &&
              reacentProduct?.map((data, i) => {
                return (
                  <div className="p-10 col" key={i}>
                    <div className="ps-block--category">
                      <Link
                        className="ps-block__overlay"
                        to={"/product/" + data?.pro_sulg}
                      ></Link>
                      <CoustomImg
                        url={
                          data?.featureImage && data?.featureImage !== undefined
                            ? URL?.API_BASE_URL + data?.featureImage
                            : "/img/message/logo_95new.png"
                        }
                        altkey={data?.title}
                        w1={165}
                        w2={200}
                        h1={80}
                        h2={120}
                      />
                      <p>
                        <TruncateText
                          text={data?.title}
                          maxLength={windowWidth > 600 ? 15 : 8}
                        />
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {reacentList?.length > 0 && (
        <div className="ps-product-list ps-clothings mt-5 px-3">
          <div className="ps-section__header my-5 border border-primary">
            <h3>Recent Listing</h3>
            <Link to={"/vendor-store/listing/" + id}>View All</Link>
          </div>

          <div className="ps-block__right"></div>
          <Slider {...settings2}>
            {reacentList &&
              reacentList?.map((data, i) => {
                return (
                  <div className="p-10" key={i}>
                    <div className="ps-block--category">
                      <a className="ps-block__overlay" href="#"></a>
                      <CoustomImg
                        url={
                          data?.featureImage && data?.featureImage !== undefined
                            ? URL?.API_BASE_URL + data?.featureImage
                            : "/img/message/logo_95new.png"
                        }
                        altkey={data?.title}
                        w1={165}
                        w2={200}
                        h1={80}
                        h2={120}
                      />
                      <p>
                        <TruncateText
                          text={data?.title}
                          maxLength={windowWidth > 600 ? 15 : 8}
                        />
                      </p>
                    </div>
                  </div>
                );
              })}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default LatestListing;
