import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";

function PageListDynemic() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const get_page_details = useSelector((state) =>
    state?.allapi?.get_page_details ? state?.allapi?.get_page_details : {}
  );
  useEffect(() => {
    if (id) {
      dispatch(allapiAction.pageDetails(id));
    }

    return () => {};
  }, [id]);

  return (
    <div>
      <div className="container">
        <div className="mt-20 mb-20 text-center">
          <h1>{get_page_details?.title}</h1>
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: get_page_details?.containt }}
        ></div>
      </div>
    </div>
  );
}

export default PageListDynemic;
