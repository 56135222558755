import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { URL } from "@/Redux/common/url";
import { Button, Modal } from "react-bootstrap";
import ImageUploading from "react-images-uploading";
// import { allapiAction } from "@/Redux/common/action";
import { URL } from "../../Redux/common/url";
import { allapiAction } from "../../Redux/common/action";
import { toast } from "react-toastify";
import ImageCropper from "../ImageCroper/ImageCropper";
function Box3() {
  const get_vendor_profiles = useSelector((state) =>
    state.allapi.get_vendor_profiles ? state.allapi.get_vendor_profiles : {}
  );
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [userBuss, setuserBuss] = useState({});
  const [images, setImages] = useState([]);
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();
  const maxNumber = 1;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    if (get_vendor_profiles) {
      // URL.API_BASE_URL + get_vendor_profiles?.sellerProfile
      setImages([
        { data_url: URL.API_BASE_URL + get_vendor_profiles?.sellerProfile },
      ]);
    }
  }, [get_vendor_profiles]);

  console.log(get_vendor_profiles);
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };
  const get_setting = useSelector((state) =>
    state.allapi.get_setting ? state.allapi.get_setting : {}
  );

  const [fileName, setFileName] = useState("");
  const [fileContant, setfileContant] = useState("");
  const [finalImg, setfinalImg] = useState("");
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [croppedImageUrl2, setCroppedImageUrl2] = useState(null);
  const submitProduct = async () => {
    const data = new FormData();
    // if (images[0]?.file) {
    //   // roledata?.role_name
    //   // data.append("logo", images[0]?.file);
    //   data.append("logo", fileContant, fileName);
    //   dispatch(allapiAction.updateBussLogovendor(data));
    // }
    data.append("logo", fileContant, fileName);
    const response = await dispatch(allapiAction.updateBussLogovendor(data));
    setfinalImg("");
    setCroppedImageUrl(null);
    setShow(false);
    if (response?.success) {
      dispatch(allapiAction.getprofilevendor({}));
    }
  };

  const [fileName2, setFileName2] = useState("");
  const [fileContant2, setfileContant2] = useState("");
  const [finalImg2, setfinalImg2] = useState("");
  const submitProduct2 = async () => {
    const data = new FormData();
    // if (images[0]?.file) {
    //   // roledata?.role_name
    //   // data.append("logo", images[0]?.file);
    //   data.append("logo", fileContant, fileName);
    //   dispatch(allapiAction.updateBussLogovendor(data));
    // }
    data.append("logo", fileContant2, fileName2);
    const response = await dispatch(allapiAction.updateBussLogo22header(data));
    setfinalImg2("");
    setCroppedImageUrl2(null);
    setShow(false);
    if (response?.success) {
      dispatch(allapiAction.getprofilevendor({}));
    }
  };
  const submitProductWithdrawal = () => {
    const data = new FormData();
    // if (images[0]?.file) {
    // roledata?.role_name
    // data.append("logo", images[0]?.file);

    if (!amount) {
      toast.error("please enter Amount");
    } else {
      dispatch(allapiAction.Withdrawalsrequset({ amount: amount }));

      setAmount("");
      // }
      setShow2(false);
    }
  };
  const updateVendorLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          // setLatitude(position.coords.latitude);
          // setLongitude(position.coords.longitude);

          const updateData = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          const response = await dispatch(
            allapiAction.updateVenerLocation(updateData)
          );

          // Send the location to your server
          // axios
          //   .post("http://localhost:3000/location", {
          //     latitude: position.coords.latitude,
          //     longitude: position.coords.longitude,
          //   })
          //   .then((response) => {
          //     console.log(response.data);
          //   })
          //   .catch((error) => {
          //     console.error("There was an error sending the location!", error);
          //   });
        },
        (error) => {
          // setError(error.message);
          toast.error(error.message);
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
      // setError("Geolocation is not supported by this browser.");
    }

    // updateVenerLocation
    // dispatch(allapiAction.updateVendorLocation({location: userBuss?.location}))
  };
  return (
    <Row gutter={[0, 20]}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">Profile Image</label>
                {/* <input
                  name="referAmount"
                  className="form-control"
                  placeholder="Enter refer Amount"
                  type="number"
                  value={userBuss?.referAmount}
                  onChange={(e) => {
                    hendleChangerole(e);
                  }}
                /> */}

                {/* <div>
                 
                    <ImageCropper
                      aspectRatio={4 / 3}
                      // onImageCrop={handleImageCrop}
                      setfileContant={setfileContant}
                      setFileName={setFileName}
                      setfinalImg={setfinalImg}
                      aspectwidth={16}
                      aspectheight={12}

                    />
                    {finalImg && (
                      <div>
                        <img
                          src={finalImg}
                          alt="Cropped"
                          width={100}
                          height={130}
                        />
                      </div>
                    )}
                  </div> */}
                <ImageUploading
                  // multiple
                  value={images}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["png", "jpeg", "jpg", "webp"]}
                  // acceptType={['jpg']}
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button>
                      &nbsp;
                      <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove images
                      </button>
                      {imageList?.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image.data_url} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageUpdate(index)}
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() => onImageRemove(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProduct();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Withdrawal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="card mx-auto ">
            {/* {!showOtpBox ? ( */}
            <div className="card-body">
              {/* <h4 className="card-title mb-4">Feature Image</h4> */}

              <div className="mb-3">
                <label className="form-label">Amount</label>
                <input
                  name="referAmount"
                  className="form-control"
                  placeholder="Enter refer Amount"
                  type="number"
                  value={amount}
                  onChange={(e) => {
                    setAmount(e?.target?.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* {!showOtpBox ? ( */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              submitProductWithdrawal();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
        {/* ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                submitChangeVerify2();
              }}
            >
              Verify
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
      <Col lg={24} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="row">
            <div className="col-12 ">
              <div style={{ width: "180px" }}>
                {/* <img
                  src={URL.API_BASE_URL + get_vendor_profiles?.sellerProfile}
                  alt=""
                  style={{ width: "100%" }}
                /> */}

                {/* <span className="jop_btn ant-tooltip-open" onClick={handleShow}>
                  Update Profile Image
                </span> */}

                {/* <button   onClick={handleShow}>edit</button> */}
              </div>
            </div>
            <div className="col-lg-3 col-12 overflow-hidden">
              <label>Shop Logo</label>
              <div style={{ width: "220px" }}>
                <div>
                  <ImageCropper
                    aspectRatio={4 / 3}
                    // onImageCrop={handleImageCrop}
                    setfileContant={setfileContant2}
                    setFileName={setFileName2}
                    setfinalImg={setfinalImg2}
                    aspectwidth={16}
                    aspectheight={16}
                    onCropComplete={setCroppedImageUrl2}
                  />
                  {croppedImageUrl2 && (
                    <div>
                      <img
                        src={croppedImageUrl2}
                        alt="Cropped"
                        width={130}
                        height={130}
                      />
                      <div>
                        <Button
                          variant="primary"
                          onClick={() => {
                            submitProduct2();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "120px" }}>
                  <img
                    src={URL.API_BASE_URL + get_vendor_profiles?.sellershopLogo}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>

                {/* <span className="jop_btn ant-tooltip-open" onClick={handleShow}>
                  Update 
                </span> */}
                {/* <button   onClick={handleShow}>edit</button> */}
              </div>
              <hr />
              <label>Shop banner</label>
              <div style={{ width: "220px" }}>
                <div>
                  <ImageCropper
                    aspectRatio={4 / 3}
                    // onImageCrop={handleImageCrop}
                    setfileContant={setfileContant}
                    setFileName={setFileName}
                    setfinalImg={setfinalImg}
                    aspectwidth={16}
                    aspectheight={8}
                    onCropComplete={setCroppedImageUrl}
                  />
                  {croppedImageUrl && (
                    <div>
                      <img
                        src={croppedImageUrl}
                        alt="Cropped"
                        width={160}
                        height={80}
                      />

                      <div>
                        <Button
                          variant="primary"
                          onClick={() => {
                            submitProduct();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ width: "120px" }}>
                  <img
                    src={URL.API_BASE_URL + get_vendor_profiles?.sellerProfile}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
                {/* <span className="jop_btn ant-tooltip-open" onClick={handleShow}>
                  Update 
                </span> */}
                {/* <button   onClick={handleShow}>edit</button> */}
              </div>
            </div>
            <div className="col-lg-9 col-12">
              <p>name : {get_vendor_profiles?.fullName}</p>
              <p>Bussiness Name : {get_vendor_profiles?.displayName}</p>
              <p>Phone : {get_vendor_profiles?.phone}</p>
              <p>Followers : {get_vendor_profiles?.follower}</p>
              <p>Like : {get_vendor_profiles?.like}</p>
              <p>
                Address : {get_vendor_profiles?.city},
                {get_vendor_profiles?.state},{get_vendor_profiles?.zip}
              </p>

              <button
                className="btn btn-primary"
                onClick={updateVendorLocation}
              >
                {" "}
                Update Location
              </button>
              {/* <p>Earning balance : {get_vendor_profiles?.earningbalance}</p>
              <p>
                {" "}
                <div style={{ width: "100px" }}>
                  <span
                    className="jop_btn ant-tooltip-open"
                    onClick={handleShow2}
                  >
                    Withdrawal
                  </span>
                </div>
              </p> */}
              {/* <p>name : {get_vendor_profiles?.fullName}</p>
              <p>name : {get_vendor_profiles?.fullName}</p> */}
            </div>
          </div>
          {/* <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Refer Amount</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.referAmount}</div>
          </div> */}
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Game commision</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.gamecommision}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Withdrwal commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.withdrwalcommission}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Minimum withdrwal
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.minimumwithdrwal}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Maxmum withdrawal
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.maxmumwithdrwal}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Minimum recharge
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.minimumrecharge}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Maxmum recharge
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.maxmumrecharge}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Vpa</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">{get_setting?.vpa}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Name</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">{get_setting?.name}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Merchant Code</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {get_setting?.merchantCode}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              withdrawal start time
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2">
              {" "}
              {moment(get_setting?.withdrawalstarttime).format("hh:mm a")}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 dashboardBoxShad m-5 cursor-pointer"
          //   onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              withdrawal end time
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="priceText2 color-risk">
              {" "}
              {moment(get_setting?.withdrawalendtime).format("hh:mm a")}
            </div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
}

export default Box3;
