import { Layout, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
// import Sidebar from "../../../../Component/Layout2/Sidebar";
// import TopBar from "../../../../Component/Layout2/TopBar";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
// import { useRouter } from "next/router";
// import EditJobComponent from "../../../../Component/Seller/jobs/EditJobComponent";
// import { render } from "react-dom";
// import EditJobComponent from "./jobs/EditJobComponent";
import Sidebar from "../../Layout2/Sidebar";
import TopBar from "../../Layout2/TopBar";
import { allapiAction } from "../../../Redux/common/action";
import { useNavigate } from "react-router-dom";
const { Header, Content } = Layout;

export default function JobsellerList() {
  const navigate = useNavigate();
  //   const page = navigate?.query?.page == null ? 1 : navigate?.query?.page;
  const [page, setPage] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [isView, setJobEditModal] = useState(false);
  const [_jobData, setJobData] = useState({});
  const dispatch = useDispatch();
  const get_vendor_created_jobs = useSelector((state) =>
    state.allapi.get_vendor_created_jobs
      ? state.allapi.get_vendor_created_jobs
      : []
  );
  const jobData = get_vendor_created_jobs /* .docs */
    ? get_vendor_created_jobs.map((data) => ({
        ...data,
        key: data._id,
        state: JSON.parse(data.state),
      })) /* .docs */
    : [];

  useEffect(() => {
    dispatch(allapiAction._getVendorCreatedJobs({}));
    return () => {};
  }, []);

  const handleCloseModal = () => {
    setJobEditModal(false);
  };

  function eidtJob(jobData) {
    setJobData(jobData);
    setJobEditModal(true);
  }

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => {
        return <div key={index}>{(page - 1) * 10 + index + 1}</div>;
      },
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      className: "table_action_col",
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
      className: "table_action_col",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      className: "table_action_col",
      render: (value, item, i) => {
        return <div key={i}>{item.state.name}</div>;
      },
    },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      className: "table_action_col",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      className: "table_action_col",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      className: "table_action_col",
    },
    {
      title: "Actions",
      key: "action",
      className: "table_action_col",
      render: (value, item, i) => {
        return (
          <div key={i}>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer fs-24 mr-10"
                onClick={() => {
                  eidtJob(item);
                }}
              >
                <i className="fa fa-edit"></i>
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer fs-24 mr-10"
                onClick={() => {
                  showDeleteConfirm(item._id);
                }}
              >
                <i className="fa fa-trash"></i>
              </span>
            </Tooltip> */}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Layout className="layout_css">
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "6px",
              padding: 10,
              minHeight: 280,
              overflowY: "auto",
            }}
          >
            {jobData && (
              <Table
                dataSource={jobData}
                columns={columns}
                className={"table antd_table"}
                /* pagination={{
                  current: page,
                  pageSize: 10,
                  onChange(current, pageSize) {
                    hendlePahegi(current);
                  },
                  hideOnSinglePage: true,
                  total: users_list?.totalDocs ? users_list?.totalDocs : 0,
                }} */
              />
            )}
            
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
