// import { useRouter } from "next/router";
import React from "react";
// import { useNavigate } from 'react-router-dom'
import IMAGES from "../../const/Image";
import { useNavigate } from "react-router-dom";

const Logo = ({ collapsed }) => {
  const navigate = useNavigate();

  return (
    <div className={collapsed ? "logo shortlogo" : "logo longlogo"}>
      {collapsed ? (
        <img
          src={IMAGES.LogoShort}
          className="cursor-pointer"
          alt=""
          onClick={() => navigate("/")}
        />
      ) : (
        <img
          src={IMAGES.LogoHeader}
          className="cursor-pointer"
          alt="Logo"
          onClick={() => navigate("/")}
        />
      )}
      {/* <img
        src={IMAGES.LogoHeader?.src}
        className="cursor-pointer"
        alt=""
        onClick={() => navigate("/")}
      /> */}
    </div>
  );
};

export default Logo;
